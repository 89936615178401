<template>
  <div id="app" class="full no-tabbing">
    <router-view />
    <notifications group="primary" />
  </div>
</template>

<script>
// say hi
var styles = [
  "background: linear-gradient(#ffc47e, #ff8a00)",
  "color: black",
  "text-align: center",
  "font-weight: bold"
].join(";");
// eslint-disable-next-line
console.log(
  "%c Hey there! Please don't worry about all these 404s, it makes it much easier on the maintainers to add songs to the site if the site looks for the file instead of them having to specify which song has which files. Cheers!",
  styles
);

document.body.className += " fade-out";

var $ = require("jquery");

function handleFirstTab(e) {
  if (e.keyCode === 9) {
    // the "I am a keyboard user" key
    window.removeEventListener("keydown", handleFirstTab);
    $("#app")[0].classList.remove("no-tabbing");
  }
}
window.addEventListener("keydown", handleFirstTab);

export default {
  name: "app",
  data() {
    return {
      songData: {},
      loaded: false,
      switchDict: {
        pipes: "Pipes",
        snare: "Snare",
        tenor: "Tenor",
        scales: "Scales",
        instrument: "Pipes Tunes",
        kilt: "Kilt Tunes",
        highland: "Highland Tunes",
        "comp-march": "C Band March Set",
        "comp-medley": "B Band Medley",
        "comp-msr": "A Band MSR"
      }
    };
  },
  created() {
    var request = new XMLHttpRequest();
    request.open("GET", "songs/info.json?v=231106", true);
    var b = this;

    request.onload = function() {
      if (this.status >= 200 && this.status < 400) {
        b.songData = JSON.parse(this.response);
        b.loaded = true;

        // eslint-disable-next-line
        // console.log('song data successfully loaded in');
      } else {
        // eslint-disable-next-line
        console.error("error fetching songs.json.");
      }
    };

    request.onerror = function() {
      // eslint-disable-next-line
      console.error("error fetching songs.json.");
    };

    request.send();
  },
  mounted() {
    var existingNotifyValue = parseInt(window.localStorage.getItem("urlNotify220502")) || 0;
    if (existingNotifyValue < 5) {
      window.localStorage.setItem("urlNotify220502", existingNotifyValue + 1);
      this.$notify({
        group: "primary",
        type: "warn",
        title: "P&D Interative has moved!",
        text:
          "P&D Interactive has a new URL. Please bookmark this new address!",
        duration: -1,
        position: "bottom right",
        width: 500
      });
    }
  }
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
}

body.no-tabbing *:focus {
  outline: none;
}

#app::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://i.postimg.cc/CKRT1zqq/pxYG.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  filter: brightness(50%);
}

#main {
  position: relative;
}

.full {
  width: 100%;
  height: 100%;
}

.link,
.link * {
  color: rgb(254, 116, 39);
  cursor: pointer;
  word-wrap: none;
}

.disabled {
  color: rgb(121, 121, 121);
  cursor: auto;
}

.orange {
  color: rgb(254, 116, 39);
}

.formlink {
  text-decoration: none;
  color: #555555;
  font-weight: bold;
  font-style: italic;
}

.vue-notification-group {
  margin: 15px;
  width: 350px !important;
}

.vue-notification {
  font-size: 14px !important;
}

body,
html {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}

body {
  opacity: 1;
  transition: 2s opacity;
}

body.fade-out {
  opacity: 0;
  transition: none;
}
</style>
