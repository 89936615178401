<template>
  <div id="main" class="main">
    <div class="flex-vertical">
      <div class="header" id="logo">
        <img src="../assets/logo.png" alt="TSC Pipes and Drums" />
      </div>
      <div class="chooser">
        <div class="flex-horizontal">
          <span>
            I play the
            <!-- <div class="dropdown-list">
            <span class="link side-padding">
              <span class="change" id="chosenInstrument">{{ $parent.switchDict[chosenInstrument] }}</span> &nbsp; <i class="fas fa-caret-down"></i>
            </span>
            <div class="drop-menu">
              <div class="drop-item" v-for="i in instruments" v-bind:key="i" v-bind:data-value="i"> <span class="link">{{ $parent.switchDict[i] }}</span>
              </div>
            </div>
            </div>-->
            <span class="orange">Pipes</span>
            and I want to learn
            <div class="dropdown-list">
              <span class="link side-padding">
                <span class="change" id="chosenLevel">{{ $parent.switchDict[chosenLevel] }}</span> &nbsp;
                <i class="fas fa-caret-down"></i>
              </span>
              <div class="drop-menu">
                <div class="drop-item" v-for="l in levels" v-bind:key="l" v-bind:data-value="l">
                  <span class="link">{{ $parent.switchDict[l] }}</span>
                </div>
              </div>
            </div>&nbsp;&nbsp;
          </span>
          <div class="arrow link" v-bind:class="{orange: hasSelected}" v-on:click="launchSongView">
            <i class="fas fa-chevron-circle-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var $ = require("jquery");
export default {
  name: "Welcome",
  data() {
    return {
      chosenInstrument: "pipes",
      chosenLevel: "instrument",
      hasSelected: false,
      loaded: false,
      instruments: [],
      levels: []
    };
  },
  created() {
    var b = this;

    var id = setInterval(function() {
      var songData = b.$parent.songData;
      if (
        songData != undefined &&
        songData != {} &&
        songData.hasOwnProperty("pipes")
      ) {
        clearInterval(id);
        var arr1 = Object.keys(songData);
        for (var i = 0; i < arr1.length; i++) {
          b.instruments.push(arr1[i]);
        }
        var arr2 = Object.keys(songData[b.instruments[0]]);
        for (var l = 0; l < arr2.length; l++) {
          b.levels.push(arr2[l]);
        }

        var selectedMenu = $(".dropdown-list");

        $(".dropdown-list").on("click", function() {
          selectedMenu = $(this);
          selectedMenu.find("div.drop-menu").toggle();
          if (selectedMenu.find("div.drop-menu").is(":visible")) {
            selectedMenu[0].style.backgroundColor = "rgb(32, 32, 32)";
            b.registerHandlers();
          } else {
            selectedMenu[0].style.backgroundColor = "transparent";
          }
        });
        $(".dropdown-list").on("blur", function() {
          $(this).hide();
          $(this)[0].style.backgroundColor = "transparent";
        });

        setTimeout(function() {
          $("body").removeClass("fade-out");
        }, 1000);
      }
    }, 100);
  },
  methods: {
    launchSongView() {
      if (this.chosenLevel == "" || this.chosenInstrument == "") {
        return;
      }
      if (
        !Object.values(this.levels).includes(this.chosenLevel) ||
        !Object.values(this.instruments).includes(this.chosenInstrument)
      ) {
        return;
      }
      this.$router.push({
        name: "view",
        params: {
          level: this.chosenLevel.toLowerCase(),
          instrument: this.chosenInstrument.toLowerCase(),
          song: "0"
        }
      });
    },
    registerHandlers() {
      var b = this;
      $(".drop-item").click(function() {
        var code = $(this).attr("data-value");
        var selectedMenu = $(this).closest(".dropdown-list");
        b[selectedMenu.find(".change")[0].id] = code;
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  box-sizing: border-box;
}

.hide {
  display: none;
}

.main {
  text-align: center;
  width: 100%;
  height: 100%;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.flex-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.chooser {
  background: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 10px;
  padding: 2em;
  margin: 30px;
  width: 550px;
  /* height: 5em;
  display: flex;
  justify-content: center;
  align-items: center; */
}

@media only screen and (max-width: 900px) {
  .chooser {
    width: 80%;
  }
}

#logo img {
  max-height: 100px;
  height: 100px;
}

.orange {
  color: rgb(254, 116, 39);
}

.arrow {
  font-size: 1.2em;
  cursor: pointer;
  text-align: center;
  /* color: rgb(32, 32, 32); */
}

.side-padding {
  padding: 0 0.5em 0 0.5em;
}

/* Dropdown List */

.dropdown-list {
  display: inline-block !important;
  /* background: #ccc; */
  position: relative;
}

.dropdown-list .drop-menu {
  z-index: 50;
  display: none;
  min-width: 100%;
  position: absolute;
  left: 0;
  top: 100%;
  background: rgb(32, 32, 32);
}

.drop-item {
  white-space: nowrap;
  text-align: left;
  padding: 2px 1em 2px 1em;
}
</style>
