<template lang="html">
<div id='main' class="main full">
  <!-- <div>Data: {{ level }}, {{ instrument }}</div> -->
  <div class="grid">

    <router-link class="back-button tab" to="/" tag="div">
      <div>
        <div class="text">
          <i class="fas fa-chevron-circle-left"></i> Home
        </div>
      </div>
    </router-link>

    <div class="song-list">

      <ul class='scroll'>
        <li class="header">{{ $parent.switchDict[level] }}</li>
        <li v-if="!loaded">Loading...</li>
        <li v-else v-for="songItem in Object.keys(songData[instrument][level])"
            v-on:click="updateSong(songItem)"
            v-bind:key="Object.keys(songData[instrument][level]).indexOf(songItem)"
            v-bind:class="{'active': song == songItem}" class="tab">

          {{ songData[instrument][level][songItem] }}
        </li>
      </ul>

    </div>

    <div class="music-container">
      <div class="image-frame" v-bind="{style: 'background-image: url('+imageInfo.img+')'}">
      </div>
      <div class="track-player">
        <div class="full track-player">
          <!-- <div > -->
          <audio id="audioPlayer" v-if="trackInfo.hasPerformance || trackInfo.hasPractice" class="tab" controls controlsList="nodownload nofullscreen" v-bind="{src: trackInfo.current}" v-on:play="action('play')"></audio>
          <div class="track-type boxed" v-if="trackInfo.hasPerformance || trackInfo.hasPractice">
            <i class="fas fa-tachometer-alt orange"></i> &nbsp;
            <span class="link tab" id="practiceTempo" v-bind:class="{
              underline: trackInfo.currentName == 'practice',
              disabled:  !trackInfo.hasPractice
            }" v-on:click="changeTrack('practice')">Practice</span> &nbsp;
            <span class="link tab" id="performanceTempo" v-bind:class="{
                underline: trackInfo.currentName == 'performance',
              disabled:  !trackInfo.hasPerformance
            }" v-on:click="changeTrack('performance')">Performance</span>
          </div>
          <p v-else>
            {{ trackNotAvailableMsg }}
          </p>

          <div class="download-buttons boxed">
          <span class="link tab" v-on:click="downloadFiles">
            <i class="fas fa-download"></i>
            &nbsp;Download All</span>
          </div>
        <!-- </div> -->


        </div>
      </div>


    </div>

  </div>

</div>
</template>

<script>
import saveAs from "file-saver";
import $ from "jquery";

export default {
  name: "SongView",
  data() {
    return {
      level: this.$route.params.level,
      instrument: this.$route.params.instrument,
      song: this.$route.params.song,
      niceSong: "",

      imageInfo: {},
      trackInfo: {},

      songData: {},
      loaded: false,

      initialTabsMade: false,
      trackNotAvailableMsg: "No track found."
    };
  },
  created() {
    var b = this;
    this.loaded = false;
    var id = setInterval(function() {
      b.songData = b.$parent.songData;
      if (b.$parent.loaded) {
        clearInterval(id);
        b.loaded = true;
        if (b.song == 0) {
          b.updateSong(Object.keys(b.songData[b.instrument][b.level])[0]);
        } else {
          b.updateSong(b.song, true);
        }
      }
    }, 100);

    setTimeout(function() {
      $("body").removeClass("fade-out");
    }, 1000);

    window.onkeydown = function(e) {
      var key = e.keyCode ? e.keyCode : e.which;
      var arr, limitToSongs, didSomething;

      if (key == 32) {
        // play/pause
        didSomething = true;
        var elm = document.getElementById("audioPlayer");
        if (elm) {
          if (elm.paused) {
            elm.play();
          } else {
            elm.pause();
          }
        }
      } else if (key == 8) {
        didSomething = true;
        b.$router.push({
          name: "welcome"
        });
      } else if ([37, 38, 39, 40].includes(key)) {
        didSomething = true;
        arr = Object.keys(b.songData[b.instrument][b.level]);
        limitToSongs = function(val) {
          var max = Object.keys(b.songData[b.instrument][b.level]).length - 1;
          var min = 0;
          return val > max ? max : val < min ? min : val;
        };
      }

      if ([37, 38].includes(key)) {
        didSomething = true;
        b.updateSong(arr[limitToSongs(arr.indexOf(b.song) - 1)]);
      } else if ([39, 40].includes(key)) {
        didSomething = true;
        b.updateSong(arr[limitToSongs(arr.indexOf(b.song) + 1)]);
      }

      if (key == 9) {
        didSomething = true;
        if (!b.initialTabsMade) {
          b.makeTabs();
          b.initialTabsMade = true;
        }
      }

      if (didSomething) {
        e.preventDefault();
      }
    };
  },
  methods: {
    updateSong(songItem, initial) {
      if (this.song == songItem && initial == undefined) {
        return;
      }

      this.song = songItem;
      this.niceSong = this.songData[this.instrument][this.level][this.song];

      this.$router.replace({
        name: "view",
        params: {
          instrument: this.instrument,
          level: this.level,
          song: songItem
        }
      });

      // Set to placeholders while loading.
      this.imageInfo.img =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=";
      this.trackInfo = {};
      this.trackNotAvailableMsg = "Loading...";

      // Reload UI before fetching urls
      this.$forceUpdate();

      var b = this;
      setTimeout(function() {
        b.assignPaths(b.instrument, b.level, b.song);
      }, 100);

      this.action("change");
    },
    checkUrl(e) {
      var t = new XMLHttpRequest();
      return t.open("HEAD", e, !1), t.send(), "404" != t.status;
    },
    assignPaths(instrument, level, song) {
      var basePath = "songs/" + instrument + "/" + level;

      var png = basePath + "/img/" + song + ".png";
      var jpg = basePath + "/img/" + song + ".jpg";

      if (this.checkUrl(png)) {
        this.imageInfo.img = png;
      } else if (this.checkUrl(jpg)) {
        this.imageInfo.img = jpg;
      } else {
        this.imageInfo.img = "https://b.webpurr.com/5zMJ.webp";
      }

      this.trackInfo.performance = basePath + "/mp3/" + song + ".mp3";
      this.trackInfo.practice = basePath + "/mp3/" + song + "-practice.mp3";
      this.trackInfo.hasPerformance = this.checkUrl(this.trackInfo.performance);
      this.trackInfo.hasPractice = this.checkUrl(this.trackInfo.practice);

      var possibleAudios = {};
      if (this.trackInfo.hasPractice) {
        possibleAudios["practice"] = this.trackInfo.practice;
      }
      if (this.trackInfo.hasPerformance) {
        possibleAudios["performance"] = this.trackInfo.performance;
      }
      this.trackNotAvailableMsg = "No track found.";

      if (this.trackInfo.currentName in possibleAudios) {
        this.trackInfo.current = possibleAudios[this.trackInfo.currentName];
      } else {
        this.trackInfo.currentName = Object.keys(possibleAudios)[0];
        this.trackInfo.current = possibleAudios[Object.keys(possibleAudios)[0]];
      }

      this.$forceUpdate();
    },
    changeTrack(type) {
      if (type == this.trackInfo.currentName) {
        return;
      }

      if (type == "practice") {
        if (this.trackInfo.hasPractice) {
          this.changeEnabled("practice");
        }
      } else if (type == "performance") {
        if (this.trackInfo.hasPerformance) {
          this.changeEnabled("performance");
        }
      }
      var audio = document.getElementById("audioPlayer");
      audio.src = this.trackInfo.current;

      audio.load();
      audio.play();
    },
    changeEnabled(type) {
      var elms = [
        document.getElementById("practiceTempo"),
        document.getElementById("performanceTempo")
      ];
      if (type == "practice") {
        this.trackInfo.current = this.trackInfo.practice;
        this.trackInfo.currentName = "practice";
      } else if (type == "performance") {
        this.trackInfo.current = this.trackInfo.performance;
        this.trackInfo.currentName = "performance";
      }

      for (var i = 0; i < elms.length; i++) {
        if (!elms[i].classList.contains("disabled")) {
          elms[i].classList.toggle("underline");
        }
      }
    },
    downloadFiles() {
      var object = {};

      if (this.trackInfo.hasPractice) {
        object[this.niceSong + " (Practice)"] = this.trackInfo.practice;
      }
      if (this.trackInfo.hasPerformance) {
        object[this.niceSong + " (Performance)"] = this.trackInfo.performance;
      }
      object[this.niceSong + " (Music)"] = this.imageInfo.img;

      for (var key in object) {
        if (object.hasOwnProperty(key)) {
          saveAs(object[key], key);
        }
      }
    },
    makeTabs() {
      $(".tab").attr("tabindex", "-1");
      $(".tab").attr("tabindex", "0");

      $(".tab").keydown(function(e) {
        if (e.which === 13) {
          $(this).click();
        }
      });
    },
    action(t) {
      if (t == "play") {
        this.$ga.event("Play Audio", this.song, this.trackInfo.currentName);
        // console.log('ga action sent - ' + 'Play Audio', this.song, this.trackInfo.currentName);
      } else if (t == "change") {
        this.$ga.event("View Song", this.song, this.level);
        // console.log('ga action sent - ' + 'View Song', this.song, this.level);
      }
    }
  }
};
</script>

<style lang="css">
/* * {
  color: white;
} */
#main {
  padding: 20px;
}
.grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 200px 1fr;
  grid-template-rows: 50px 1fr;
  height: calc(100% - 40px);
  width: calc(100% - 40px);
}
.grid > div:last-child {
  margin-bottom: 0px;
}
@media only screen and (max-width: 900px) {
  #main {
    padding: 0;
  }
  .grid {
    display: block;
    width: 100%;
    height: 100%;
    /* grid-template-rows: 50px auto 100vh;
    grid-template-columns: 1fr; */
  }

  .grid > div {
    width: 100%;
    margin-bottom: 20px;
    grid-column: 1;
  }
  .back-button {
    order: 1;
  }
  .song-list {
    order: 2;
  }
  .song-list ul {
    max-width: 340px;
    margin: 0 auto !important;
  }
  .music-container {
    /* height: calc(100vh - 40px); */
    grid-rows: 1;
    grid-template-rows: 1fr auto !important;
    order: 3;
  }
  .music-container .image-frame {
    height: 100vh;
  }
  .music-container .track-player {
    flex-direction: column;
  }
  .music-container .track-player > div > * {
    margin-bottom: 10px;
  }
  #app {
    overflow-y: auto;
  }
  audio {
    width: 100%;
  }
}

.back-button {
  background: rgb(59, 59, 59);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
  font-size: larger;
  height: 50px;
  cursor: pointer;
}

.music-container {
  background: grey;
  grid-row: 1/3;
  grid-column: 2/3;
  display: grid;
  grid-template-rows: 1fr 55px;
}
.music-container .image-frame {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
}
.music-container .track-player {
  background: rgb(241, 243, 244);
}

.song-list {
  overflow-y: auto;
}
.song-list {
  text-align: center;
}
.song-list ul {
  list-style: none;
  margin: 0;
  width: inherit;
  word-wrap: break-word;
  background: rgb(59, 59, 59);
  padding: 10px;
  border-radius: 5px;
}
.song-list ul li {
  background: darkgrey;
  padding: 0.4em 0 0.4em 0;
  cursor: pointer;
  margin: 0 auto;
  margin-bottom: 5px;
  border-radius: 5px;
  max-width: 300px;
}
.song-list ul li.header {
  background: none;
  color: white;
  cursor: auto;
  border-radius: 0px;
  font-weight: bold;
}
.song-list ul li.active {
  background: #28a745;
  color: white;
}

.track-player {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.boxed {
  padding: 2px 6px 2px 6px;
  background: rgb(217, 217, 217);
  border-radius: 10px;
}

.underline {
  text-decoration: underline;
}
</style>
