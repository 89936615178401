/* jshint esversion:6 */

import Vue from 'vue';
import Router from 'vue-router';
import Welcome from './components/Welcome.vue';
import SongView from './components/SongView.vue';


Vue.use(Router);

export default new Router({
  base: window.location.pathName,
  routes: [
    {
      path: '/',
      name: 'welcome',
      component: Welcome
    },
    {
      path: '/view/:instrument/:level/:song',
      name: 'view',
      component: SongView
    }
  ]
});
