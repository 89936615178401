/* jshint esversion:6 */

import Vue from 'vue';
import App from './App.vue';
import router from './router';
window.$ = require('jquery');
window.JQuery = require('jquery');
import VueAnalytics from 'vue-analytics'
import Notifications from 'vue-notification'

Vue.use(Notifications)
Vue.use(VueAnalytics, {
  id: 'UA-109129983-3',
  router,
  checkDuplicatedScript: true,
  autoTracking: {
    skipSamePath: true
  }
})

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
